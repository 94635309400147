<template>
  <div class="form">
    <div class="wrapper" v-if="!isMobile || !$store.state.popup.id && (!isMobile || !$store.state.is_popup_shown)">
      <Backlink title="questionnaire" service="refMortgage" backlink="/refinance-mortgage/programs"/>
      <div class="content illustration illustration_shield">
        <div class="content__container">
          <p class="promo promo_blocks2">{{ "rm_payment_title" | localize }}</p>

          <form @submit.prevent="submitHandler">
            <div class="form__group">
              <div class="form__element" :class="{ form__element_disabled: calc_type == '2'}">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="payment"
                      class="input_number input_slider"
                      inputmode="numeric"
                      pattern="[0-9]*"
                      v-bind="attrs" v-on="on"
                      @blur="$v.payment.$touch()"
                      @keypress="onKeyPressHandler"
                      :disabled="calc_type == '2'"
                      @keyup="onKeyUpPriceHandler('payment', payment)"
                      @change="syncSlider('payment', 'paymentSlider', true); calc_month()"
                      :class="{ invalid: $v.payment.$dirty && !$v.payment.required }"
                      :hint="
                        ($v.payment.$dirty && !$v.payment.required
                          ? 'form_reqiered'
                          : 'empty') | localize
                      "
                      :label="'s4p18_label_1' | localize"
                      :placeholder="'s4p18_placeholder_1' | localize"
                      append-icon="icon-₪"
                      ref="payment"
                      outlined
                    ></v-text-field>
                  </template>
                  <span>{{ "tt_payment" | localize }}</span>
                </v-tooltip>
                <v-slider
                  v-model="paymentSlider"
                  :min="min_payment"
                  :max="max_payment"
                  :disabled="calc_type == '2'"
                  @input="syncSlider('payment', 'paymentSlider'); calc_month()"
                  class="slider--container"
                ></v-slider>
                <div class="slider--ticks">
                  <span>{{ min_payment.toLocaleString() }}</span>
                  <span>{{ max_payment.toLocaleString() }}</span>
                </div>
              </div>
            </div>

            <v-radio-group v-model="calc_type" row>
              <v-radio :label="'calc_payment' | localize" value="1" on-icon="icon-radio-on" off-icon="icon-radio-off"></v-radio>
              <v-radio :label="'calc_month' | localize" value="2" on-icon="icon-radio-on" off-icon="icon-radio-off"></v-radio>
            </v-radio-group>

            <div class="form__group">
              <div class="form__element" :class="{ form__element_disabled: calc_type == '1'}">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="month"
                      class="input_number input_slider"
                      inputmode="numeric"
                      pattern="[0-9]*"
                      v-bind="attrs" v-on="on"
                      :disabled="calc_type == '1'"
                      @blur="$v.month.$touch()"
                      @keypress="onKeyPressHandler"
                      @change="syncSlider('month', 'monthSlider', true); calc_payment()"
                      :class="{ invalid: $v.month.$dirty && !$v.month.required }"
                      :hint="
                        ($v.month.$dirty && !$v.month.required
                          ? 'form_reqiered'
                          : 'empty') | localize
                      "
                      :label="'month_payment' | localize"
                      placeholder="120"
                      ref="month"
                      outlined
                    ></v-text-field>
                  </template>
                  <span>{{ "tt_month" | localize }}</span>
                </v-tooltip>
                <v-slider
                  v-model="monthSlider"
                  :min="min_month"
                  :max="max_month"
                  :disabled="calc_type == '1'"
                  @input="syncSlider('month', 'monthSlider'); calc_payment()"
                  class="slider--container"
                ></v-slider>
                <div class="slider--ticks">
                  <span>{{ min_month.toLocaleString() }}</span>
                  <span>{{ max_month.toLocaleString() }}</span>
                </div>
              </div>
            </div>

            <div class="pricetexts">
              <div class="pricetext">
                <div class="pricetext__icon pricetext__icon_1"></div>
                <p>
                  {{ "s4p18_text_2_1" | localize }}
                  {{ min_payment.toLocaleString() }} <br />
                  {{ "s4p18_text_2_2" | localize }}
                </p>
              </div>
              <div class="pricetext" v-if="recommendation">
                <div class="pricetext__icon pricetext__icon_1"></div>
                <p>
                  {{ "s4p18_text_3_1" | localize }}
                  {{ recommendation.toLocaleString() }} <br />
                  {{ "s4p18_text_3_2" | localize }}
                </p>
              </div>
              <div class="pricetext">
                <div class="pricetext__icon pricetext__icon_3"></div>
                <p>
                  {{ "s4p18_text_4_1" | localize }}
                  {{ max_payment.toLocaleString() }} <br />
                  {{ "s4p18_text_4_2" | localize }}
                </p>
              </div>
            </div>

            <div class="error__container">
              <p class="error" v-if="error">{{ error | localize }}</p>
              <p v-else class="info info_3">
                {{ "payment_text_1" | localize }}
              </p>
            </div>

            <button
              type="button"
              class="button button__form"
              :class="{ button__form_disabled: !success }"
              @click="submitHandler"
            >
              {{ "form_continue" | localize }}
            </button>
          </form>
        </div>
      </div>
    </div>

    <PopupText/>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import Backlink from '@/components/base/Backlink.vue'
import PopupText from '@/components/PopupText.vue'
import { clientMixin } from '@/mixins/clientMixin.js'
import { commonMixin } from '@/mixins/commonMixin.js'
import { numberMixin } from '@/mixins/numberMixin.js'
import { saveMixin } from '@/mixins/saveMixin.js'
import { formMixin } from '@/mixins/formMixin.js'

export default {
  name: 'Refinance_mortgage_payment',
  components: { Backlink, PopupText },
  mixins: [clientMixin, numberMixin, saveMixin, formMixin, commonMixin],
  data: () => ({
    month: null,
    payment: null,
    monthSlider: null,
    paymentSlider: null,
    calc_type: null,
    min_payment: 1200,
    recommendation: null,
    max_payment: 34000,
    min_month: 60,
    max_month: 360,
    success: false,
    userServiceId: null,
    userId: null,
    error: null
  }),
  created () {
    this.$store.commit('togglePopupText', { id: 15, withTitle: false, type: 'popup_info' })
    const refMortgage = this.$cookies.get('refMortgage') || {}
    this.userId = this.$store.state.user ? this.$store.state.user.id : 0
    this.calc_type = refMortgage.calc_type || '1'
    this.paymentSlider = refMortgage.payment || this.min_payment
    this.payment = this.formatNumber(this.paymentSlider)
    this.calc_month()
  },
  mounted () {
    const refMortgage = this.$cookies.get('refMortgage') || {}

    this.$store.commit('loading', true)
    this.axios.get('user_service', {
      params: {
        user_id: this.userId,
        service_id: this.$store.state.services.refMortgage
      }
    }).then((response) => {
      this.userServiceId = response.data.id || 0
      this.axios.get('program/payments', {
        params: {
          user_service_id: this.userServiceId,
          is_refinance: 1,
          capital: refMortgage.cost
        }
      }).then((response) => {
        this.min_payment = response.data.min_payment
        this.recommendation = response.data.recommendation
        this.max_payment = response.data.max_payment
        this.min_month = response.data.min_month
        this.max_month = response.data.max_month
        this.paymentSlider = refMortgage.payment || this.min_payment
        this.payment = this.formatNumber(this.paymentSlider)
        this.calc_month()
        this.success = true
        this.$store.commit('loading', false)
      }).catch((error) => {
        this.$store.commit('loading', false)
        this.error = error.response.data && error.response.data.error && typeof error.response.data.error === 'string' ? error.response.data.error : 'server_error'
        this.$scrollTo('.error__container', 300, { offset: -400 })
      })
    }).catch((error) => {
      this.error = error.response.data && error.response.data.error && typeof error.response.data.error === 'string' ? error.response.data.error : 'server_error'
      this.$store.commit('loading', false)
      this.$scrollTo('.error__container', 300, { offset: -400 })
    })
  },
  validations: {
    month: { required },
    payment: { required }
  },
  methods: {
    calc_month () {
      const percent = (this.parseNumber(this.payment) - this.min_payment) * 100 / (this.max_payment - this.min_payment)
      this.month = this.monthSlider = Math.round(this.max_month - ((this.max_month - this.min_month) * percent / 100))
    },
    calc_payment () {
      const percent = (this.month - this.min_month) * 100 / (this.max_month - this.min_month)
      this.paymentSlider = Math.round(this.max_payment - ((this.max_payment - this.min_payment) * percent / 100))
      this.payment = this.formatNumber(this.paymentSlider)
    },
    submitHandler () {
      this.payment = this.parseNumber(this.payment)
      if (this.$v.$invalid) {
        this.$v.$touch()
        this.$scrollTo('.step', 300, { offset: -150 })
        this.success = false
        return
      }

      const refMortgage = this.$cookies.get('refMortgage') || {}
      refMortgage.payment = this.payment
      refMortgage.calc_type = this.calc_type
      this.$cookies.set('refMortgage', refMortgage)
      const saveObj = this.calc_type === '1' ? { payment: parseInt(this.payment, 10), months: null } : { payment: null, months: parseInt(this.month, 10) }
      this.saveUserService('refMortgage', '/refinance-mortgage/reg-bank-offers', saveObj)
    }
  }
}
</script>
